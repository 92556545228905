.headerr {
    background: #0d6efd;
    color: #ffffff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 250px;
 
 
  }
  
  .headerr input[type="text"] {
    padding: 5px;
    width:300px;
  }
  .headerr-right {
    display: flex;
    align-items: center;
  }
  
  .notification {
    background: red;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .profile {
    margin-right: 20px;
  }
  
  .settings {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
  @media (max-width: 768px) {
  
    .headerr {
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  
    }