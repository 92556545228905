
.seat-selection-container {
    max-width: 400px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.seat-selection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.seat-selection-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.close-btn {
    font-size: 1.5rem;
    cursor: pointer;
}

.seat-selection-guide {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.seat-selection-guide div {
    display: flex;
    align-items: center;
    color: black;
}


.seat-map {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seat-row {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 5px 0; 
}


.seat {
    width: 45px; 
    height: 45px; 
    background-color: #8f8f8f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.seat.available {
    background-color: #a6cfe2;
}

.seat.selected {
    background-color: #61b846;
}

.seat.booked {
    background-color: #5a5a5a;
    cursor: not-allowed;
}

.spacer {
    visibility: hidden;
    width: 50px; 
    height: 50px; 
}


.seat.driver {
    background-color: transparent; 
    padding: 0; 
    cursor: default; 
}

.seat.driver img {
    width: 40px; 
    height: 40px; 
    border-radius: 50%; 
    border: 2px solid #8f8f8f; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
}


.continue-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.arrow{
    color: black;
}