.services-section {
  background-color: #2C3E50;
  padding: 50px 20px;
  text-align: center;
}

.services-header h3 {
  font-size: 1.2em;
  color: #E74C3C;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.services-header .description {
  font-size: 19px; /* Reduced from 36px */
  color: #fff;
  margin-bottom: 40px;
}

.courier-services-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.service-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  text-align: center;
  padding: 20px;
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.service-title {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #333;
}

.service-description {
  font-size: 1rem;
  color: #666;
}
.read-more {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  animation: colorChange 2s infinite alternate; 
  transition: transform 0.3s ease; 
}

@keyframes colorChange {
  0% {
    color: #007bff; 
  }

  100% {
    color: #ff5733; 
  }
}

.read-more:hover {
  transform: scale(1.1); 
  text-decoration: underline;
}
/* Add styling for the button */
.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  animation: colorChange 2s infinite alternate; 
  transition: transform 0.3s ease; 
}
@keyframes colorChange {
  0% {
    background-color: #007bff; 
  }
  50%{
    background-color: #0a4380;
  }

  100% {
    background-color: #ff5733; 
  }
}

.read-more-btn:hover {
  background-color: #0056b3;
}

/* Other existing styles */


/* Responsive Design */
@media (max-width: 768px) {
  .courier-services-container {
    flex-direction: column;
    padding: 10px;
  }

  .service-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .service-title {
    font-size: 1.2rem;
  }

  .service-description {
    font-size: 0.9rem;
  }
}
