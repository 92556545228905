.contact-info-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #fff;
  }
  
  .info-box {
    flex: 1;
    padding: 20px;
    margin: 10px;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    margin: 0 auto 10px; 
  }

  
  .contact-icon {
    background: url(https://th.bing.com/th?q=Light+Blue+Email+Icon&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.5&pid=InlineBlock&mkt=en-WW&cc=NG&setlang=en&adlt=moderate&t=1&mw=247) no-repeat center center;
    background-size: contain;
  }
  
  .call-icon {
    background: url(https://th.bing.com/th/id/OIP.OTTzo9Cl684rPyl5ypeYqwHaHa?w=172&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7) no-repeat center center;
    background-size: contain;
  }
  
  .hours-icon {
    background: url(https://cdn.medvet.com/app/uploads/2023/07/CE-Navy-Time-Icon.png?strip=all&lossy=1&ssl=1) no-repeat center center;
    background-size: contain;
  }
  
  .info-box h3 {
    margin: 10px 0;
    font-size: 1.2em;
    color: #333;
  }
  
  .info-box p {
    margin: 0;
    color: #777;
    font-size: 0.9em;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-info-container {
      flex-direction: column;
    }
  
    .info-box {
      margin: 20px 0;
    }
  }
  