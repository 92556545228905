.sidebar {
    width: 250px;
    background: #1f2a36;
    color: #ffffff;
    padding: 20px;
    position: static;
    
  }
  
  .sidebar h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 10px;
  }
  
  .sidebar nav ul li a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .sidebar nav ul li ul {
    margin-left: 15px;
  }
  
  .sidebar nav ul li ul li a {
    font-size: 14px;
  }
  .sidebar a{
    font-size: 20px;
    cursor: pointer;
    margin: 30px 0px;
  }
  .sidebar a:hover{
    color: rgb(178, 190, 253);
  }

  .logout-button {
    font-size: 20px;
    cursor: pointer;
    margin: 30px 0px;
    background-color: transparent;
    color: #ffffff;
    border: none;
  }

  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    }