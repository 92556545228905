.order-history {
    padding: 20px;
  }
  
  .heaader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filters {
    display: flex;
    gap: 10px;
  }
  
  .filters button {
    padding: 10px 20px;
    border: none;
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .filters button.active {
    background-color: #ff4d4f;
    color: white;
  }
  
  .filters .date-picker {
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  thead th {
    padding: 15px;
    text-align: left;
  }
  
  tbody tr {
    border-bottom: 1px solid #f2f2f2;
  }
  
  tbody td {
    padding: 15px;
  }
  
  .status {
    font-weight: bold;
  }
  
  .status.delivered {
    color: #52c41a;
  }
  
  .status.cancelled {
    color: #ff4d4f;
  }
  
  .actions {
    position: relative;
  }
  
  .actions button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .actions .dropdown {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .actions:hover .dropdown {
    display: block;
  }
  
  .actions .dropdown button {
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    background-color: white;
    cursor: pointer;
  }
  
  .actions .dropdown button:hover {
    background-color: #f2f2f2;
  }
  
  .admin-storage-img{
    width: 100px;
    border-radius: 5px;
  }
  .admin-storage-desc{
    font-size: 13px;
    margin:10px 0px
  }
  .admin-storage-wgt{
    font-size: 11px;
  }
  .admin-storage-warehouse .admin-storage-warehouse-header{
    font-size: 11px;
    font-weight: bold;
  }
  .admin-storage-warehouse .admin-storage-warehouse-content{
    font-size: 14px;
    margin-bottom: 10px;
    
  }
  .admin-logistics-customer{
    background-color: #102a43;
    padding: 5px;
    color: white;
    font-size: 10px;
    border-radius: 5px;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
    .filters {
      flex-direction: column;
      gap: 5px;
    }
  
    table {
      font-size: 14px;
    }
  
    thead th, tbody td {
      padding: 10px;
    }
  }
  