.admin-modal-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.admin-dialog-container {
    background-color: whitesmoke !important;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px; /* Increased width */
    width: 100%;
    height: 95vh;
    color: black;
    overflow: scroll;
  }

  .close-button{
    color: white;
    font-size: 20px;
  }
  .close-button:hover{
    color: whitesmoke;
  }
  .admin-modal-overlay input{
    background-color: white;
    border: 1px solid lightgray;
    font-size: 13px;
  }
  .admin-modal-overlay select{
    background-color: white;
    border: 1px solid lightgray;
    font-size: 13px;
  }
  .admin-modal-label{
    font-size: 13px;
    font-weight: bold;
  }
  .admin-modal-button{
    background-color: rgb(231, 76, 60);
    color: white;
    font-size: 20px;
  }
  .admin-modal-button {
    width: 100%;
    padding: 10px;
    background-color: rgb(231, 76, 60);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .add-category-button {
    width: fit-content;
    height: fit-content;
  }

  .logistics-flex {
    display: flex;
    flex-direction: column;
  }
  
  .logistics-flex .route {
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
  }
  
  .category-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  
  .category-group .form-group {
    margin-right: 10px;
    flex: 1;
    /* min-width: 200px; */
  }

  .route-path{
    width: 100%;
  }

  .route-path-header{
    text-align: center;
    background-color: white;
    color: #2c3e50;
    display: flex;

  }
  .route-path-header h5{
    border-right: solid 1px #2c3e50;
    padding: 10px;
    width: 100%;
  }
  .route-path-header .last{
    border-right: none;
  }
  .route-path-header .option{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }
  .remove-category-button {
    background-color: red;
    color: white;
    border: none;
    padding: 1px 3px;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .add-category-button {
    margin: 20px 0px;
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }