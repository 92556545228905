.footer {
    background-color: #0a1931;
    color: #fff;
    padding: 50px 20px;
    text-align: left;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-about,
  .footer-services,
  .footer-links {
    width: 22%;
    margin-bottom: 20px;
  }
  
  .footer-about h3,
  .footer-services h3,
  .footer-links h3 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .footer-about p,
  .footer-services ul,
  .footer-links ul {
    font-size: 14px;
    line-height: 1.8;
  }
  
  .footer-services ul li,
  .footer-links ul li {
    margin-bottom: 10px;
    text-decoration: none;
  }
  .footer-services ul li a{
    text-decoration: none;
    color: white;

  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {

    .footer-content {
      flex-direction: column;
      align-items: center; /* Center all sections */
      text-align: center;  /* Center text within each section */
    }
    .footer-about,
    .footer-services,
    .footer-links {
      width: 100%;
      margin-bottom: 30px;
      
    }
    .footer-services ul li,
    .footer-links ul li {
      text-decoration: none;
      list-style-type: none;
    }
  
  }
  