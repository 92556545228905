.how-it-works-container {
  text-align: center;
  padding: 50px 20px;
}

.how-it-works-container h3 {
  color: #E74C3C;
  font-size: 1.2rem;
  margin-bottom: 50px;
}

.steps {
  display: flex;
  justify-content: space-around;
  position: relative;
  flex-wrap: wrap; 
}

.step-item {
  width: 200px;
  position: relative;
  margin-bottom: 30px;
}

.icon-container {
  height: 100px;
  width: 100px;
  border: 3px solid #E74C3C;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.step-number {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #E74C3C;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-item h4 {
  color: #2C3E50;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.step-item p {
  color: #7F8C8D;
}

.steps::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #E74C3C;
  z-index: -1;
}

.steps::before {
  top: 20%;
  left: 15%;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .steps {
      flex-direction: column; 
      align-items: center; 
  }
  
  .steps::before {
      display: none; 
  }

  .step-item {
      width: 100%; 
      max-width: 300px;
  }

  .icon-container {
      height: 80px;
      width: 80px;
  }

  .step-number {
      top: 40px;
  }
}
