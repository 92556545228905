
.bus-type-container {
    padding: 20px;
    background-color: #f8f9fa;
  
  }
  
  .bus-type-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .bus-type-container h3 {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  
  .bus-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e3f2fd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .bus-image img {
    width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  .bus-info {
    flex-grow: 1;
    margin-left: 80px; 
  }
  
  .bus-info h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .bus-info p {
    margin: 5px 0;
    color: #555;
  }
  
  .price-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .price {
    font-size: 1.75rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  
  .view-seats-btn {
    padding: 10px 20px;
    background-color: #0a1931;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px; 
  }
  
  .view-seats-btn:hover {
    background-color: #102341;
  }
  /* selection.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* SeatSelection.css */
.seat-selection-container {
  padding: 20px;
}

.seat-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}

.continue-btn {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

  
  /* Responsiveness */
  @media (max-width: 768px) {
    .bus-card {
      flex-direction: column;
      align-items: center;
    }
  
    .bus-image img {
      width: 150px;
      margin-bottom: 20px;
    }
  
    .bus-info {
      margin-left: 0;
      text-align: center;
    }
  
    .price-info {
      align-items: center;
    }
  }
    