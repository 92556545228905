.logistics-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-image:linear-gradient(60deg,
    rgba(0,0,0,0.8) 0%,
    rgba(0,0,0,0.5) 100%), 
    url('../../images//storage.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    min-height: 100vh;
  }
  
  .left-section {
    width: 40%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    height: 50vh;
  }
  
  .left-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .left-section p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #ffffff;

  }
  
  .right-section {
    width: 50%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9); /* Light background for form */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    color: #2c3e50;
  }
  
  .form-group input[type="range"] {
    width: 80%;
    margin-right: 10px;
  }
  
  .submit-button {
    background-color: #e74c3c;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #c0392b;
  }
  .storagee-container {
    
    
    color: white;
    padding: 90px 20px;
    text-align: center;
    background-image:linear-gradient(60deg,
    rgba(0,0,0,0.8) 0%,
    rgba(0,0,0,0.5) 100%), 
    url('../../images//storage.jpg');
  
  }
  .logistics-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin: 20px 0px 0px 0px;
  }
  .logistic-header{
    text-align: center;
    background-color: #2c3e50;
    color: white;
    padding: 10px;
  }
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .logistics-page {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .left-section,
    .right-section {
      width: 100%;
      margin-bottom: 30px;
    }
    .left-section{
      padding-top: 100px;
    }
  
    .left-section h2 {
      font-size: 2rem;
    }
  
    .left-section p {
      font-size: 1rem;
    }
  
    .form-group label,
    .form-group input,
    .form-group select {
      font-size: 14px;
    }
  
    .submit-button {
      padding: 12px;
      font-size: 14px;
    }
  }
  