
.storage-container {
    
    
    color: white;
    padding: 90px 20px;
    text-align: center;
    background-image:linear-gradient(60deg,
    rgba(0,0,0,0.8) 0%,
    rgba(0,0,0,0.5) 100%), 
    url(../../images/warehouse.jpeg);
  
  }
  
  .storage-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: white;
    color: #fff;
    min-height: 100vh;
  }
  
  .storage-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .storage-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .storage-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  
  .storage-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;

  }
    
  .cal-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cal-btn {
    background-color: #ff0000;;
    color: white;
  }
  .text-area {
    margin-bottom: 20px;
  }
  
  .text-area label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .text-area textarea {
    width: 100%; 
    height: 100px;
    padding: 10px; 
    font-size: 16px; 
    box-sizing: border-box; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    resize: vertical; 
  }
  
  .left-secxtion {
    width: 40%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.4); 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    height: 50vh;
    color: #2C3E50;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .storage-page {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .left-secxtion {
      width: 100%;
      margin-bottom: 30px;
      padding-top: 100px;
    }
  
    .left-secxtion h2 {
      font-size: 2rem;
    }
  
    .left-secxtion p {
      font-size: 1rem;
    }
  
    .form-group label,
    .form-group input,
    .form-group select {
      font-size: 14px;
    }
  
    .submit-button {
      padding: 12px;
      font-size: 14px;
    }
  }
  