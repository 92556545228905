.overlay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    margin: 0;
    position: relative; 
  }

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    margin: 20px auto; 
    border-radius: 10px;
    z-index: 10;
    position: relative;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    max-width: 800px; 
  }
  .blur-bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 
    z-index: 9;
    background: rgba(0, 0, 0, 0.5); 
    opacity: 1; 
  }
  
  .welcome-section {
    background: url("https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360");
    background-size: cover;
    background-position: center;
    background-image:linear-gradient(60deg,
                            rgba(0,0,0,0.3) 0%,
                            rgba(0,0,0,0.8) 100%), 
                            url(https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360);
    padding: 40px;
    width: 50%;
    height: 50vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  
  .welcome-section h2 {
    margin: 0 0 10px;
  }
  
  .welcome-section p {
    margin: 0;
  }
  
  .login-form, .form-content{
    padding: 40px;
    width: 50%;
    position: relative;
  }
  
  .login-form h3 {
    margin: 0 0 20px;
  }
  
  .login-form .close-btn {
    position: absolute;
    top: 30px;
    right: 20px;
    background-color: transparent;
    border: none;
    color: #ff4b4b;
    cursor: pointer;
    text-decoration: none;
  }
  
  .login-form form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-form .forgot-password {
    color: #ff4b4b;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  .login-form button {
    padding: 10px;
    background-color: #020e28;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-form p {
    margin-top: 20px;
  }
  
  .login-form .signup-link {
    color: #ff4b4b;
    text-decoration: none;
  }
  .error-message {
    background-color: #e74c3c;
    color: #ffffff;          
    border-radius: 5px;    
    padding: 10px;
    margin-bottom: 15px;      
    font-size: 14px;         
    text-align: center;      
  }
  .input-field .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #666;
  }
  /* Responsive */
  @media (max-width: 768px) {
    .overlay-containerr{
      height: 100vh;
    }
    .login-container {
      flex-direction: column;
      align-items: center;
      max-width: 90%; 
    margin: 10px; 

    }
  
    .welcome-section {
      width: 100%;
      border-radius: 10px;
    }
  
    .login-form {
      width: 100%;
      border-radius: 0 0 10px 10px;
    }
  }
  