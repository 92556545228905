.invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .search-filter .search{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
    flex-wrap: wrap; /* Ensures responsiveness */
  }
  .header h2{
    margin: 20px;
  }
  
  .search-input, 
  .date-input, 
  .submit-btn, 
  .today-btn, 
  .new-invoice-btn {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-btn, 
  .today-btn, 
  .new-invoice-btn {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border: none;
  }
  
  .new-invoice-btn {
    background-color: #28a745;
    margin-right: 10px;
  }
  
  .search-input {
    flex: 1;
    min-width: 100px;
  }
  
  .date-input {
    min-width: 150px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  div.scrollable-container {
    width: 100%;
    overflow-x: auto;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  .status.completed {
    
    color: #6cc070;
    padding: 3px 5px;
    
  }
  
  .status.pending {
    color: #e74c3c;
    padding: 5px 10px;
  }
  
  .action-btn {
    background: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    margin: 2px 0;
    height: 10px;
    width: 50px;
  }
  
  .action-btn:hover {
    background-color: #0056b3;
  }
  
  .dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100px;
  }
  
  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #ddd;
    background: white;
  }
  
  .dropdown-item:hover {
    background-color: #f4f4f4;
  }
  
  .booking-details {
    padding: 20px;
    background-color: #14274e; /* Dark blue background */
    color: #ffffff; /* White text */
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  .admin-datatable-details {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:10px
  }
  .admin-datatable-main{
    padding: 20px;
    background-color: #14274e; /* Dark blue background */
    color: #ffffff; /* White text */
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  .booking-detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .booking-label {
    font-weight: bold;
    font-size: 16px;
    flex-basis: 30%; /* Adjust this to fit your design */
  }
  
  .booking-value {
    font-weight: normal;
    font-size: 16px;
    flex-basis: 70%; /* Adjust this to fit your design */
    text-align: left; /* Align text to the left */
  }
  .admin-action{
    width: 100px !important;
  }
  .admin-action-main{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .admin-dt-container{
    display: grid;
    gap: 10px;
  }
  .admin-datatable-header{
    display: flex;
    justify-content: space-between;
  }
  .admin-datatable-header button{
    background-color: white;
    border: none;
    border-radius: 10px;
    min-width: 150px;
    padding: 10px;
    cursor: pointer;
    transition: all 500ms;
  }
  .admin-datatable-header-button{
    background-color: white;
    border: none;
    border-radius: 10px;
    min-width: 150px;
    padding: 10px;
    cursor: pointer;
    transition: all 500ms;
  }
  .admin-datatable-header-button:hover{
    background-color: #e3eef9;
  }
  .admin-datatable-header button:hover{
    background-color: #e3eef9;
  }
  .admin-datatable-details input{
    border: none;
    border-radius: 5px;
    padding: 0px 10px;
  }
  .admin-clear-btn{
    background-color: white;
    font-size: 20px;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  .admin-clear-btn:hover{
    background-color: #102a43;
    color: white;
  }
  
  @media (max-width: 768px) {
    .booking-details {
      padding: 15px;
    }
  
    .booking-detail-row {
      flex-direction: column;
      margin-bottom: 10px;
    }
  
    .booking-label, .booking-value {
      font-size: 14px;
    }
  }
  .modal-header{
    height: 50px;
    font-size: 1.2em;
    font-weight: 700;
  }
  .modal-content {
    background-color: whi;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px; /* Increased width */
    width: 100%;
    color: #fff;
  }
  
  .modal-row {
    display: flex;
    gap: 20px; /* Space between the fields */
    margin-bottom: 15px;
  }
  
  .modal-field {
    flex: 1; /* Ensures both fields take equal space */
  }
  
  .modal-label {
    display: block;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
  }
  
  .modal-input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
  }
  
  .modal-button {
    width: 100%;
    padding: 10px;
    background-color: #2a9d8f;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #21867a;
  }
  .close-button {
    cursor: pointer;
    float: right;
    font-size: 24px;
    font-weight: bold;
    color: #aaa;
    height: 20px;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  /* Trips Table */
  .person-profile{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 7px;
    flex-direction: column;
  }
  .person-profile .email{
    font-size: 11px;
    font-style: italic;
    cursor: pointer;
  }
  .person-profile .email:hover{
    color: #102a43;
    text-decoration: underline;
  }
  .person-profile .phoneCopy{
    font-size: 10px;
  }
  .person-profile .phoneCopy:hover{
    color: #102a43;
  }
  
  @media (max-width: 768px) {
    .invoice-table {
      padding: 10px;
    }
  
    .search-filter {
      flex-direction: column;
      align-items: stretch;
  
    }
  
    table {
      font-size: 14px;
    }
  }
  