.add-body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color:  rgba(0, 0, 0, 0.5);
  }
.add-container {
    position: relative;
    max-width: 700px;
    width: 100%;
    background: #f0eeee;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  .add-container header {
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    text-align: center;
  }
  .add-container .add-form {
    margin-top: 30px;
  }
  .add-form .input-boox {
    width: 100%;
    margin-top: 20px;
  }
  .input-boox label {
    color: #333;
  }
  .add-form :where(.input-boox input, .select-boox) {
    position: relative;
    height: 40px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0 15px;
  }
  .input-boox input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }
  .add-form .column {
    display: flex;
    column-gap: 15px;
  }
  .add-form .pickup-boox {
    margin-top: 20px;
  }
  .pickup-boox h3 {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .add-form :where(.pickup-option, .pickup) {
    display: flex;
    align-items: center;
    column-gap: 50px;
    flex-wrap: wrap;
  }
  .add-form .pickup {
    column-gap: 5px;
  }
  .pickup input {
    accent-color: rgb(189, 135, 54);
  }
  .add-form :where(.pickup input, .pickup label) {
    cursor: pointer;
  }
  .pickup label {
    color: #707070;
  }
  .addresss :where(input, .select-boox) {
    margin-top: 15px;
  }
  .select-boox select {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: #707070;
    font-size: 1rem;
  }
  .add-form button {
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    background:  #00aaff;
  }
  .add-form button:hover {
    background-color: #184e69;
  }
  /*Responsive*/
  @media screen and (max-width: 500px) {
    .add-form .column {
      flex-wrap: wrap;
    }
    .add-form :where(.pickup-option, .pickup) {
      row-gap: 15px;
    }
  }
 