.expertise-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #020e28;
  color: wheat;
}

.expertise-text {
  width: 50%;
  padding-right: 20px;
}

.expertise-image {
  width: 50%;
  overflow: hidden;
  position: relative;
  height: 350px; 
}

.slideshow {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slideshow img {
  width: 100%;
  object-fit: contain;
  flex-shrink: 0;
  height: 100%;
}

/* responsiveness */
@media (max-width: 768px) {
  .expertise-container {
    flex-direction: column;
    text-align: center;
  }

  .expertise-text, .expertise-image {
    width: 100%;
  }

  .expertise-text {
    padding-right: 0;
  }
}
