/* App.css */

/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  /* App Container */
  .dashboard {
    display: flex;
    min-height: 100vh;
  }
  
  /* Sidebar */
  .sidebar {
    flex: 0 0 250px;
    background: #1f2a36;
    color: #fff;
    padding: 20px;
  }
  
  .sidebar a {
    color: #b8c7ce;
    display: block;
    padding: 10px 0;
    text-decoration: none;
  }
  
  .sidebar a:hover {
    color: #ffffff;
  }
  
  /* Main Content Area */
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  /* headerr */
  .headerr {
    background: #0d6efd;
    padding: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .headerr-right {
    display: flex;
    align-items: center;
  }
  
  .headerr-right span.notification {
    background: red;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .headerr-right span.profile {
    margin-right: 15px;
  }
  
  /* Order Table */
  .order-table {
    flex: 1;
    padding: 20px;
    background: linear-gradient(120deg, #2b5876, #4e4376);
    border-radius: 10px;
    margin: 20px;
  }
  
  .order-table h2 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .order-table table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-table th, .order-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .order-table th {
    background: #f9f9f9;
    color: #333;
  }
  
  .order-table td {
    color: #666;
  }
  
  .order-table td .status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    font-weight: bold;
  }
  
  .order-table td .fulfilled {
    background-color: #28a745;
  }
  
  .order-table td .confirmed {
    background-color: #ffc107;
  }
  
  .order-table td .partially-shipped {
    background-color: #17a2b8;
  }
  
  /* Buttons */
  button.add-order {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  button.view-details {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  button.edit {
    background-color: #ffc107;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  button.delete {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .App {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
  
    .headerr {
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order-table {
      margin-left: 0;
      padding: 10px;
    }
  
    .order-table table {
      font-size: 14px;
    }
  
    .order-table th, .order-table td {
      padding: 10px;
    }
  }
  