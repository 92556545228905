.reasons-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    background-color: #fff;
    color:  #0a1931;
  }
  
  .left-secction {
    width: 45%;
  }
  
  .left-secction h4 {
    color: red;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .left-secction h1 {
    font-size: 48px;
    margin-bottom: 30px;
  }
  
  .reasons-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .reasons-list li {
    font-size: 18px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
  }
  
  .reasons-list li::before {
    content: '✔️'; 
    position: absolute;
    left: 0;
    top: 0;
    color: red;
  }
  
  .right-secction {
    width: 45%;
    background-color: #0a1931;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: #2c3e50;
   
  }
  
  .form-group input[type="range"] {
    width: 80%;
    margin-right: 10px;
  }
  
  .submit-button {
    background-color: red;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: darkred;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .reasons-page {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .left-secction,
    .right-secction {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .left-secction h1 {
      font-size: 36px;
    }
  
    .reasons-list li {
      font-size: 16px;
    }
  
    .form-group label,
    .form-group input,
    .form-group select {
      font-size: 14px;
    }
  
    .submit-button {
      padding: 12px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .left-secction h1 {
      font-size: 28px;
    }
  
    .reasons-list li {
      font-size: 14px;
    }
  }
  