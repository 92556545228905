.new-booking{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  background-color: #1b2a4e;
  min-height: 100vh;
  position: relative;
}
.new-booking-backdrop{
  position: absolute;
  width: 100%;
  height: 100vh;
}
.booked-items {
  background-color: #1c2a4e;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
}
.booked-items-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: fit-content;
  padding: 30px 20px;
  transition: width 1s;
  text-align: left;
}
.booked-items-spacebetween {
  display: flex;
  justify-content: space-between;
}

.booking-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #1b2a4e;
  color: #fff;
  font-family: Arial, sans-serif;
  min-height: 100vh;
}

.order-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.add-order {
  background: #0d6efd;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
}

.fulfilled {
  background: green;
}

.confirmed {
  background: orange;
}

.partially-shipped {
  background: blue;
}

.view-details,
.edit,
.delete {
  background: none;
  border: none;
  cursor: pointer;
}

.view-details {
  color: #0d6efd;
}
@media (max-width: 768px) {
  .order-table {
    margin-left: 0;
  }

  table {
    font-size: 14px;
  }
}
