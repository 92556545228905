/* LocationCards.css */
.location-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #020e28;
  }
  
  .card {
    background-color: whitesmoke;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease-in-out;
  }
  .location {
    font-size: 2rem;
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    background-color: #020e28;
  }
  .terminal{
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 40px;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .address {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .card p {
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .location-icon {
    color:  #020e28;
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  .phone-icon {
    color:  #020e28;
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .location-cards {
      grid-template-columns: 1fr;
    }
  }
  