.overlay-container {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  padding-top: 40px;
  margin: 20px auto;
  border-radius: 10px;
  z-index: 10;
  position: relative;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  max-width: 900px; 
  width: 100%; /* Adjust width for responsiveness */
}

.blur-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); 
  -webkit-backdrop-filter: blur(5px); 
  z-index: 9;
  background: rgba(0, 0, 0, 0.5); 
  opacity: 1;
}

.welcomee-section {
  background: url("https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360");
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(60deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 100%), 
                    url("https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360");
  padding: 40px;
  width: 50%;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcomee-section h2 {
  margin: 0 0 10px;
}

.welcomee-section p {
  margin: 0;
}

.form-content {
  padding: 30px;
  width: 60%;
  height: auto;
  position: relative;
}
.form-content button {
  padding: 10px;
  background-color: #020e28;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-content h2 {
  margin: 0 0 20px;
}

.form-content .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: #ff4b4b;
  cursor: pointer;
  text-decoration: none;
}

.form-content form {
  display: flex;
  flex-direction: column;
}

.input-field {
  position: relative;
  margin-bottom: 15px;
}

.input-field input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  padding-right: 40px; /* Add padding to the right for the icon */
}

.input-field .password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #666;
}
.input-field label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  font-size: 16px;
  color: #666;
  pointer-events: none;
  transition: 0.2s ease;
}

.input-field input:focus + label,
.input-field input:not(:placeholder-shown) + label {
  top: -20px;
  left: 0;
  font-size: 12px;
  color: #333;
}

.gender-options {
  margin-bottom: 15px;
}

.gender-options input {
  margin-right: 10px;
}

.policy-text {
  margin: 20px 0;
}

.policy-text input {
  margin-right: 10px;
}

.bottom-link {
  margin-top: 20px;
}

.bottom-link a {
  color: #ff4b4b;
  text-decoration: none;
}
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.popup-content {
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-content p {
  font-size: 16px;
  color: #555;
}
.error-message {
  background-color: #e74c3c;
  color: #ffffff;          
  border-radius: 5px;    
  padding: 10px;
  margin-bottom: 15px;      
  font-size: 14px;         
  text-align: center;      
}
.validation-overlay { position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
   height: 100%; 
   backdrop-filter: blur(8px); 
  background-color: rgba(0, 0, 0, 0.5); 
   display: flex;
 justify-content: center;
  align-items: center;
   z-index: 1000; }
   .validation-container { position: relative; 
    width: 400px; 
    padding: 20px; 
    background-color: white;
     border-radius: 8px; 
     text-align: center;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
    } 
    .close-btn { 
      position: absolute; 
    top: 10px; 
    right: 10px;
     background: none; 
     border: none; 
     font-size: 20px; 
     cursor: pointer; 
    } 
     
    .trip-detail-header { 
      font-size: 18px; 
      margin-bottom: 15px;
     } 
     
     .reference-input {
      width: 100%;
       padding: 10px; 
       margin-bottom: 15px;
        border: 1px solid #ccc; 
        border-radius: 4px; }
        
        .scheduleButton { 
          padding: 10px 20px;
           background-color: #007bff; 
           color: white; 
           border: none;
            border-radius: 4px; 
            cursor: pointer; 
          }

             .scheduleButton:hover { 
              background-color: #0056b3;
             }

/* Responsive styles */
@media (max-width: 768px) {
  
  .overlay-container{
    height:130vh;
  }
  .signup-container {
    flex-direction: column;
    align-items: center;
    max-width:90%; 
    margin: 10px; 
    padding-top:40px ;
    width: 100%;
  }

  .welcomee-section {
    width: 100%;
    border-radius: 10px;
    height: 50vh;
    margin-top: 20px;
  
  }

  .form-content {
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}
