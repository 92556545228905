.hero-container {
    display: flex;
    flex-wrap: wrap; 
    height: auto; 
    min-height: 90vh;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(60deg,
                              rgba(0,0,0,0.3) 0%,
                              rgba(0,0,0,0.8) 100%), 
                              url(https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360)
  }
  
  .signup .form-details {
    padding: 0 20px;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(60deg,
                              rgba(0,0,0,0.3) 0%,
                              rgba(0,0,0,0.8) 100%), 
                              url(https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360)
  }

.hero-left {
    position: relative;
    width: 100%; 
    max-width: 50%; 
    background-color: #020e28;
    clip-path: polygon(0 0,80% 0, 100% 100%, 0% 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    color: white;
    box-sizing: border-box; 
}

.hero-content {
    max-width: 80%;
}

.hero-content h4 {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.hero-content h1 {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 1.5;
}

.hero-buttons {
    display: flex;
    gap: 15px;
}

.services-btn, .call-btn {
    background: white;
    border: none;
    color: #ff0000;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.services-btn:hover, .call-btn:hover {
    background-color: #ffcccc;
}

.call-btn {
    display: flex;
    align-items: center;
}

.call-btn i {
    margin-right: 10px;
}

.call-btn span {
    font-weight: bold;
}

.hero-right {
    width: 100%; 
    max-width: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; 
    box-sizing: border-box;
    overflow: hidden; 
}


@media (max-width: 768px) {
    .hero-container {
        flex-direction: column; 
        height: auto; 
        background-color: white;
    }

    .hero-left, .hero-right {
        width: 100%; 
        max-width: 100%; 
    }

    .hero-left {
        clip-path: none;
        padding: 20px;
    }

    .hero-content h1 {
        font-size: 36px;
    }

    .hero-right {
        height: 200px;
        background-color: none;
        height: auto; 
    }
}
