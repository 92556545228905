/* General container styling */
.user-profile-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #1b2a4e;
    color: #fff;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .breadcrumb {
    font-size: 0.9rem;
    color: #aaa;
  }
  
  /* Main content layout */
  .profile-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .left-column,
  .right-column {
    flex: 1;
    padding: 10px;
  }
  
  /* Profile Card Styling */
  .profile-card {
    background-color: #23395d;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  


  
  /* Contact Info & Project Status */
  .contact-infos {
    background-color: #23395d;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .contact-infos input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
  }
  
  .save-button {
    background-color: #4a90e2;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  input[readonly] {
    background-color:  #c8d1db; /* Greyed-out background for read-only */
  }
  
  input:not([readonly]) {
    background-color: #fff; /* Regular background for editable */
  }
  

  

  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .profile-content {
      flex-direction: column;
    }
  
    .left-column,
    .right-column {
      width: 100%;
      padding: 0;
    }
  }
  