/* About Section Styles */
.about-container {
    background: url("https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360");
    background-size: cover;
    background-position: center;
    color: white;
    padding: 50px 20px;
    text-align: center;
    background-image:linear-gradient(60deg,
                              rgba(0,0,0,0.3) 0%,
                              rgba(0,0,0,0.8) 100%), 
                              url(https://img.freepik.com/premium-photo/row-white-vans-are-parked-city-street_1214132-8111.jpg?w=360)
  }
  
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .about-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
   .term-btn {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #cc5252;
    color: white;
    height: 50px;
  }
  .about-buttons a{
    text-decoration: none;
  }
  
  /* Mission, Vision, and Values Section Styles */
  .mv-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 50px 20px;
    background-color: #f5f5f5;
  }
  .mv-container2 {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 50px 20px;
    background-color: white;
    color: white;
  }
  
  .mv-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
  }
  .mv-card2 {
    background: #020e28;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
  }
  
  .mv-icon {
    font-size: 3rem;
    color: #020e28;
    margin-bottom: 15px;
  }
  
  .mv-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .mv-card p, .mv-card ul {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .mv-card ul {
    list-style-type: none;
    padding-left: 0;
  }
  .coreValue {
    background-color:  #020e28;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    
  }
  
  .coreValue-list {
    list-style-type: none;
    padding-left: 0;
}

.coreValue-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 10px;
}

.coreValue-item span {
    color: #fff;
    margin-right: 8px;
    font-weight: bold;
}
/* /CoreValues.css */

.core-values-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.icon {
  width: 50px;
  margin-bottom: 10px;
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.value {
  text-align: center;
  margin: 20px 0;
  width: 100%;
  max-width: 300px;
}

.value .underline {
  width: 50px;
  height: 5px;
  background-color: #020e28;
  margin: 0 auto 10px;
}

@media (min-width: 768px) {
  .values {
    flex-direction: row;
  }

  .value {
    width: 30%;
  }
}



  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-content h1 {
      font-size: 2rem;
    }
    .about-content p {
      font-size: 1rem;
    }
    .about-buttons {
      flex-direction: column;
    }
    .mv-container {
      flex-direction: column;
      align-items: center;
    }
    .mv-card {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .coreValue {
        justify-content: center; 
        text-align: center;
      }
    
      .coreValue-item {
        align-items: center;
      }
  
  
  }
  