.header-container {
  background-color: rgba(243, 243, 243, 0.5);
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%; 
    max-width: 80%;
  margin: 0px auto;
  border-radius: 8px;
  box-sizing: border-box; 
  box-shadow: 0px 4px 8px #0000001a;

}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  align-items: center;
  background-color: rgba(44, 62, 80, 0.4); 
  padding: 10px 10px;
}

.header-flex {
  display: flex;
  gap: 40px;
  padding-left: 20px;
}

.header-tab {
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 18px;
  transition: all 0.3s ease;
}

.header-tab-active {
  color: #ffffff;
}

.header-tab-active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #00aaff;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-tab-inactive {
  color: rgba(255, 255, 255, 0.5);
}

.tab-text {
  font-size: 16px;
}

.header-container-main {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.5); 
  color: #333;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.header-subtitle {
  font-size: 16px;
  margin-bottom: 20px;
}

.trip-type {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.header-container-main-2 {
  background-color: rgba(249, 249, 249, 0.5); 
  padding: 20px;
  border-radius: 10px;
  border:  1px solid #ddd; 


}

.simple-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.simple-flex select,
.simple-flex input, .header-container-main-2 input {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.iconCircle {
  background-color: #ddd;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scheduleButton {
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  width: 15%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.checkbox-label .label-bus {
  text-wrap: nowrap;
}

/* Media Queries for Smaller Screens */
@media (max-width: 1200px) {
  .header-container {
    width: 90%; 
    padding: 15px; 
  }
}
@media (max-width: 768px) {
  .header-container {
    width: 100%;
    max-width: 95%; 
    padding: 15px; 
}


  .header-main {
    flex-direction: column;
  }

  .header-flex {

    gap: 10px; 
  }

  .header-tab {
    padding: 8px 15px;
    font-size: 14px; 
  }

  .header-title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .header-subtitle {
    font-size: 14px; 
    margin-bottom: 15px;
  }

  .trip-type {
 
    gap: 5px;
  }

  .header-container-main-2 {
    padding: 15px;

  }

  .simple-flex {
    flex-direction: column; 
    gap: 5px;
  }

  .simple-flex select,
  .simple-flex input {
    font-size: 14px; 
    padding: 8px; 
  }

  .iconCircle {
    padding: 8px; 
  }

  .scheduleButton {
    padding: 12px 20px; 
    font-size: 16px; 
  }
}
.pick-at-home{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  column-gap: 10px;
}

@media (max-width: 480px) {
  .header-container {
    padding: 10px; 
  }

  .header-title {
    font-size: 18px; 
  }

  .header-subtitle {
    font-size: 13px; 
  }

  .header-tab {
    padding: 5px 10px; 
    font-size: 12px; 
  }

  .simple-flex select,
  .simple-flex input {
    font-size: 12px; 
    padding: 7px; 
  }

  .scheduleButton {
    padding: 10px 15px; 
    font-size: 14px; 
  }
}