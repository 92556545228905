.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #1b2a4e;
    padding: 20px;
  }
  
  .change-password-form {
    background: #16233c;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* color: #fff; */
    max-width: 400px;
    width: 100%;
  }
  
  .change-password-form h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #fff;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .password-field {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .password-field input {
    color: #333; 
    background-color: #fff;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    color: black;
  }
  
  .change-password-btn {
    width: 100%;
    padding: 10px;
    background-color: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .change-password-btn:hover {
    background-color: #133a68;
  }
  .error-message {
    background-color: #e74c3c;
    color: #ffffff;          
    border-radius: 5px;    
    padding: 10px;
    margin-bottom: 15px;      
    font-size: 14px;         
    text-align: center;      
  }
  .password-toggle-icon{
    color: #333;
    position: relative;
    right: 10px;
  }
  @media (max-width: 768px) {
    .change-password-form {
      padding: 20px;
    }
  }
  